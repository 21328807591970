<template>
  <div style="width: 80px">
    <div v-if="image === null">
      <svg class="profile-icon" width="52" height="52" viewBox="0 0 52 52" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.8333 45.5C8.44306 45.4929 6.50714 43.5569 6.5 41.1667V39C6.5 34.2333 15.1667 30.3333 26 30.3333C36.8333 30.3333 45.5 34.2333 45.5 39V41.1667C45.4929 43.5569 43.5569 45.4929 41.1667 45.5H10.8333ZM17.3333 15.1667C17.3333 10.3802 21.2135 6.5 26 6.5C30.7865 6.5 34.6667 10.3802 34.6667 15.1667C34.6667 19.9531 30.7865 23.8333 26 23.8333C21.2135 23.8333 17.3333 19.9531 17.3333 15.1667Z"
              fill="white"/>
      </svg>

      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="#7F8393"/>
      </svg>
    </div>

    <div v-else>
      <img :src="image" height="80" alt="">
    </div>

  </div>
</template>

<script>
export default {
  name: "AvatarIcon",
  props: {
    image: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
.profile-icon {
  position: absolute;
  top: 13px;
  left: 14px;
}
</style>
