<template>
  <ion-page>
    <Header title="Settings"/>
    <ion-content :fullscreen="true">
      <div class="avatar-section">
        <ion-item lines="none">

          <AvatarIcon/>

          <div class="info-section">
            <ion-row class="title">
              <ion-label>You are Not logged in</ion-label>
            </ion-row>

            <ion-row class="info">
              <ion-label>Click here to login or register</ion-label>
            </ion-row>
          </div>
        </ion-item>
      </div>

      <div class="item-section">
        <ion-item lines="none">
          <ion-label>
            Dark Mode
          </ion-label>
          <ion-toggle slot="end" name="dark-mode" color="danger" mode="ios" checked/>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            Notifications
          </ion-label>
          <ion-toggle slot="end" name="notifications" color="danger" mode="ios" checked/>
        </ion-item>
        <ion-item lines="none" detail>
          <ion-label>
            Language
          </ion-label>
          <ion-select interface="action-sheet" v-model="language">
            <ion-select-option value="de" selected>German</ion-select-option>
            <ion-select-option value="en">English</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item lines="none" detail>
          <ion-label>
            Help
          </ion-label>
        </ion-item>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import Header from "@/layouts/Header.vue";
import {IonContent, IonItem, IonLabel, IonPage, IonRow, IonSelect, IonSelectOption, IonToggle} from '@ionic/vue';
import {ref} from 'vue'
import AvatarIcon from '@/components/icons/AvatarIcon.vue';

export default {
  name: 'settings',
  components: {
    Header,
    IonContent,
    IonPage,
    IonToggle,
    IonLabel,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonRow,
    AvatarIcon
  },
  setup: () => {
    const language = ref('de')
    return {
      language
    }
  }
}
</script>
