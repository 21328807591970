
import Header from "@/layouts/Header.vue";
import {IonContent, IonItem, IonLabel, IonPage, IonRow, IonSelect, IonSelectOption, IonToggle} from '@ionic/vue';
import {ref} from 'vue'
import AvatarIcon from '@/components/icons/AvatarIcon.vue';

export default {
  name: 'settings',
  components: {
    Header,
    IonContent,
    IonPage,
    IonToggle,
    IonLabel,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonRow,
    AvatarIcon
  },
  setup: () => {
    const language = ref('de')
    return {
      language
    }
  }
}
